import styled from 'styled-components'
import PropTypes from 'prop-types'
import DialogTitle from '@mui/material/DialogTitle'
import Modal from 'components/Modal'
import { Button } from 'components/Button'
import { Status } from 'styles/utils'
import { Spinner } from 'components/Spinner'
import ErrorIcon from 'assets/icons/error.svg'
import { numberWithCommas } from 'utils/numbers'
import dayjs from 'dayjs'

const TransactionDetailsWrapper = styled.div`
    max-width: 100%;
    width: 800px;
    padding: 0 30px 30px;

    .transactiond-data {
        h6 {
            font-size: 16px;
            margin-right: 10px;
        }
    }
`

export default function TransactionDetails({
    open,
    onClose,
    payment,
    title,
    handleRefund,
    loading,
    error,
    onError,
}) {
    const { transfer_status, message, data } = payment || {}

    const { provider, recipient, payment_id, charge, process_time, refund } =
        data || {}

    const invalidMerchantRef = transfer_status === 'invalid_provider_reference'

    const transferFailed = transfer_status === 'failed' || invalidMerchantRef

    const details = [
        {
            title: 'Payment ID',
            value: payment_id,
            type: 'text',
        },
        {
            title: 'Provider',
            value: provider?.provider || 'N/A',
            type: 'text',
        },
        provider?.provider_status
            ? {
                  title: 'Provider Transaction Status',
                  value: provider?.provider_status,
                  type: 'tag',
              }
            : {},
        {
            title: 'MYEC Generated Reference',
            value: provider?.reference || 'N/A',
            type: 'text',
        },
        provider?.provider_reference
            ? {
                  title: 'Provider Reference',
                  value: provider?.provider_reference || 'N/A',
                  type: 'text',
              }
            : {},
        {
            title: 'Session ID',
            value: provider?.session_id || 'N/A',
            type: 'text',
        },
        ...(!transferFailed
            ? [
                  {
                      title: 'Amount Paid',
                      value: `NGN ${numberWithCommas(provider?.amount)}`,
                      type: 'text',
                  },
                  {
                      title: 'Myecurrency Charges',
                      value: `NGN ${numberWithCommas(charge)}`,
                      type: 'text',
                  },
                  {
                      title: 'Provider Charge',
                      value: `NGN ${numberWithCommas(provider?.charges)}`,
                      type: 'text',
                  },
                  {
                      title: 'Total Debit',
                      value:
                          provider?.charges && provider?.amount
                              ? `NGN${
                                    parseFloat(provider?.charges) +
                                    parseFloat(provider?.amount)
                                }`
                              : '',
                      type: 'text',
                  },
              ]
            : []),

        {
            title: 'Provider Transaction Status',
            value: invalidMerchantRef ? 'failed' : transfer_status,
            type: 'tag',
        },

        transferFailed
            ? {
                  title: 'Refund Completed',
                  value: refund ? 'yes' : 'no',
                  type: 'tag',
              }
            : {},
        {
            title: 'Timestamp',
            value: dayjs(process_time).format('DD MMM, YYYY | HH:mmA'),
            type: 'text',
        },

        {
            title: 'Message',
            value: message,
            type: 'text',
        },
    ]

    const recipientData = [
        {
            title: 'Account Name',
            value: recipient?.account_name,
            type: 'text',
        },
        {
            title: 'Account No.',
            value: recipient?.account_no,
            type: 'text',
        },
        {
            title: 'Bank',
            value: recipient?.bank_name,
            type: 'text',
        },
    ]

    const detailsList = details.filter(value => Object.keys(value).length !== 0)

    return (
        <Modal open={open} onClose={onClose} maxWidth="1000px">
            {title && <DialogTitle>{title} </DialogTitle>}
            {loading && (
                <div>
                    <Spinner />
                    <p className="text-center mt-3"> Loading...</p>
                </div>
            )}

            <TransactionDetailsWrapper>
                {provider ? (
                    <>
                        <ul className="transactiond-data">
                            <h5 className="mb-2">Payment Information</h5>
                            {detailsList?.map(item => (
                                <li className="mb-1" key={item.value}>
                                    <div className="d-flex align-items-center">
                                        <h6>{item.title}:</h6>
                                        <div>
                                            {item.type === 'tag' ? (
                                                <Status status={item.value}>
                                                    {item.value}
                                                </Status>
                                            ) : (
                                                <p>{item.value}</p>
                                            )}
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>

                        {!transferFailed && (
                            <ul className="transactiond-data mt-5">
                                <h5 className="mb-2">Recipient Details</h5>
                                {recipientData?.map(item => (
                                    <li className="mb-1" key={item.value}>
                                        <div className="d-flex align-items-center">
                                            <h6>{item.title}:</h6>
                                            <div>
                                                {item.type === 'tag' ? (
                                                    <Status status={item.value}>
                                                        {item.value}
                                                    </Status>
                                                ) : (
                                                    <p>{item.value}</p>
                                                )}
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        )}

                        {transferFailed && !refund && payment_id && (
                            <div className="mt-3">
                                <Button
                                    variant="contained"
                                    onClick={() => handleRefund(payment_id)}
                                >
                                    Process Refund
                                </Button>
                            </div>
                        )}
                    </>
                ) : (
                    <h5 className="text-center mt-3">{message}</h5>
                )}

                {error && (
                    <div className="mt-3">
                        <div className="d-flex justify-content-center">
                            <img
                                src={ErrorIcon}
                                alt="error"
                                className=" mb-2"
                            />
                        </div>
                        <h4 className="text-center mb-4">
                            {error?.message || 'Payment not found!'}
                        </h4>
                        <div className="d-flex justify-content-center">
                            <Button onClick={onError}>Done</Button>
                        </div>
                    </div>
                )}
            </TransactionDetailsWrapper>
        </Modal>
    )
}

TransactionDetails.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    title: PropTypes.string,
    payment: PropTypes.object,
    handleRefund: PropTypes.func,
    loading: PropTypes.bool,
    error: PropTypes.object,
    onError: PropTypes.func,
}
